<template>
  <div class="box">
    <div class="header">
      <div class="header-search">
        <van-nav-bar
          :title="title"
          left-text=""
          left-arrow
          @click-left="onClickLeft"
        >
        </van-nav-bar>
      </div>
    </div>
    <div class="content contentbox loadingbox" v-if="loadingfalg">
      <van-loading size="24px" vertical>加载中...</van-loading>
    </div>
    <div class="content contentbox" v-if="showage && !loadingfalg">
      <BasicTop :basicTop="basicTop" numberrows="2"  v-if="appStatus == 1"/>
      <BasicTop :basicTop="basicTopResult" numberrows="2"  v-if="appStatus != 1"/>
      <Steps
        :levels="levels"
        v-if="levels && appStatus == 1"
        :hierarchy="hierarchy"
      />
      <div class="content-show">
        <van-tabs v-model="active" :animated="true">
          <!-- NOTE 等待审批页面：关联信息、基本信息、联系人 -->
          <van-tab title="关联信息" name="a" v-if="appStatus == 1">
            <van-list :finished="true" finished-text="没有更多了">
              <van-collapse v-model="activeNames">
                <van-collapse-item title="关联第一阶段招标" name="1" icon="label">
                  <van-cell-group class="group">
                    <div v-for="item in relatedInfo" :key="item.title">
                      <van-cell v-if="item.multi" :title="item.title" style="width: 100%;"  @click="toSecondDetail(item.to, item.key)" >
                        <template #default>
                          <div v-html="details[item.value]" style="white-space: pre-line"></div>
                        </template>
                      </van-cell>
                      <van-cell v-else :title="item.title" :value="`${item.to ? '查看' : details[item.value] || ''}`" @click="toSecondDetail(item.to, item.key)" />
                    </div>
                  </van-cell-group>
                </van-collapse-item>
              </van-collapse>
            </van-list>
          </van-tab>
          <van-tab title="基本信息" name="b" v-if="appStatus == 1">
            <van-list :finished="true" finished-text="没有更多了">
              <van-collapse v-model="activeNames">
                <van-collapse-item title="基本信息" name="1" icon="label">
                  <van-cell-group class="group">
                    <van-cell v-for="item in basicInfo" :key="item.title" :title="item.title" :value="`${item.to ? '查看' : details[item.value] || ''}`" @click="toSecondDetail(item.to, item.key)" />
                  </van-cell-group>
                  <van-cell-group class="group">
                    <van-cell v-for="(item, index) in details.bidAttachmentVos" :key="index" :title="item.typeName">
                      <div>
                        <a 
                          v-if="item.title.substring(item.title.lastIndexOf('.') + 1) != 'rar' && item.title.substring(item.title.lastIndexOf('.') + 1) != 'zip'"
                          :href="'/api-ec/fileDownload/FileuploadAction!download?id=' + item.id"
                        >
                          {{ item.title }}
                        </a>
                        <span v-else @click="onCompression">{{item.title}}</span>
                      </div>
                    </van-cell>
                  </van-cell-group>
                </van-collapse-item>
              </van-collapse>
            </van-list>
          </van-tab>

          <!-- NOTE 审批结果 -->

          <van-tab title="详情页" name="a" v-if="appStatus != 1">
            <van-list :finished="true" finished-text="没有更多了">
              <van-collapse v-model="activeNames">
                <van-collapse-item title="关联第一阶段招标" name="1" icon="label">
                  <van-cell-group class="group">
                    <div v-for="item in relatedInfo" :key="item.title">
                      <van-cell v-if="item.multi" :title="item.title" style="width: 100%;"  @click="toSecondDetail(item.to, item.key)" >
                        <template #default>
                          <div v-html="details[item.value]" style="white-space: pre-line"></div>
                        </template>
                      </van-cell>
                      <van-cell v-else :title="item.title" :value="`${item.to ? '查看' : details[item.value] || ''}`" @click="toSecondDetail(item.to, item.key)" />
                    </div>
                  </van-cell-group>
                </van-collapse-item>
              </van-collapse>
            </van-list>
          </van-tab>

          <van-tab title="包内详情" name="b" v-if="appStatus != 1">
            <van-list :finished="true" finished-text="没有更多了">
              <van-collapse v-model="activeNames">
                <van-collapse-item title="包1" name="1" icon="label">
                  <van-cell-group class="group">
                    <van-cell v-for="item in basicInfo" :key="item.title" :title="item.title" :value="`${item.to ? '查看' : details[item.value] || ''}`" @click="toSecondDetail(item.to, item.key)" />
                  </van-cell-group>
                  <van-cell-group class="group">
                    <van-cell v-for="(item, index) in details.bidAttachmentVos" :key="index" :title="item.typeName">
                      <div>
                        <a 
                          v-if="item.title.substring(item.title.lastIndexOf('.') + 1) != 'rar' && item.title.substring(item.title.lastIndexOf('.') + 1) != 'zip'"
                          :href="'/api-ec/fileDownload/FileuploadAction!download?id=' + item.id"
                        >
                          {{ item.title }}
                        </a>
                        <span v-else @click="onCompression">{{item.title}}</span>
                      </div>
                    </van-cell>
                  </van-cell-group>
                </van-collapse-item>
              </van-collapse>
            </van-list>
          </van-tab>

          <van-tab title="联系人" name="c">
            <van-list
              v-model="loading"
              :finished="finished"
              finished-text="没有更多了"
              @load="onLoad"
              :immediate-check="false"
            >
              <van-collapse v-model="activeNames">
                <van-collapse-item title="企业咨询联系人" name="1" icon="label">
                  <div
                    class="record"
                    v-for="(item, index) in details.bidcompetitiveContactsVos"
                    :key="index"
                  >
                    <van-cell-group class="group">
                      <van-cell title="单位" :value="item.qyCorp"></van-cell>
                      <van-cell title="联系人" :value="item.qyName"></van-cell>
                      <van-cell title="电话" :value="item.qyTel"></van-cell>
                      <van-cell title="Email" :value="item.qyEmail"></van-cell>
                    </van-cell-group>
                  </div>
                </van-collapse-item>
              </van-collapse>
            </van-list>
          </van-tab>

          <!-- TODO 审批结果页面：详情页、包内详情、联系人、审批记录 -->


          <van-tab title="审批记录" name="d" v-if="appStatus != 1">
            <div
              class="record"
              v-for="(item, index) in details.wfapproveinfos"
              :key="index"
            >
              <van-cell-group>
                <van-cell title="审批层级" :value="item.node"></van-cell>
                <van-cell title="审批节点" :value="item.nodename"></van-cell>
                <van-cell title="审批人" :value="item.userName"></van-cell>
                <van-cell title="审批时间" :value="item.enddatetime"></van-cell>
                <van-cell title="审批结论" :value="item.resultType"></van-cell>
                <van-cell
                  title="审批意见"
                  :value="item.result"
                  v-if="item.resultType == '不同意'"
                ></van-cell>
              </van-cell-group>
            </div>
          </van-tab>
        </van-tabs>
      </div>
    </div>
    <div
      class="footer"
      v-if="appStatus == 1 && showage == true && !loadingfalg"
    >
      <div class="footer-left" @click="agree" v-if="show">
        <span><van-icon name="success" size="20"/></span>
        <span>同意</span>
      </div>
      <div class="footer-right" @click="noagree" v-if="show">
        <span><van-icon name="cross" size="20"/></span>
        <span>不同意</span>
      </div>
      <div class="footer-left" v-if="!show">
        <span><van-icon name="success" size="20"/></span>
        <span>提交中</span>
      </div>
      <div class="footer-right" v-if="!show">
        <span><van-icon name="cross" size="20"/></span>
        <span>不同意</span>
      </div>
    </div>
    <Noagree v-if="!showage" :submitflag="submitflag" />
  </div>
</template>
<script>
import { Dialog } from "vant";
import { Toast } from "vant";
export default {
  data() {
    return {
      loadingfalg: true,
      submitflag: false,
      show: true,
      showage: true,
      title: "",
      loading: false,
      finished: true,
      isLoading: false,
      active: "a",
      activeNames: ["1", "2", "3", "4", "5", "6"],
      supplierList: [],
      details: [],
      levels: null,
      hierarchy: "",
      basicTopResult: {
        data: [],
        columns: [
          {
            prop: "prodName",
            label: "招标物资",
          },
          {
            prop: "competitiveName",
            label: "项目名称",
          }
        ],
      },

      basicTop: {
        data: [],
        // NOTE 详情页 等待审批 头部信息列
        columns: [
          {
            prop: "prodName",
            label: "招标物资",
          },
          {
            prop: "competitiveName",
            label: "项目名称",
          }
        ]
      },

      // 关联信息
      relatedInfo: [
        {title: '关联第一阶段招标', value: 'bidfilecode'},
        {title: '招标项目单位', value: 'item'},
        {title: '招标委托单名称', value: 'bidconsignName'},
        {title: '需求类型', value: 'reqTypeName'},
        {title: '原单据物资信息', value: 'endtime', to: 'ecCompetitiveOfferStageTwoMate', key: 'projectId'},
        {title: '供应商', value: 'supplier', multi: true}
      ],
      // 基本信息
      basicInfo: [
        {title: '竞争性报价方案名称', value: 'competitiveName'},
        {title: '编制日期', value: 'createdated'},
        {title: '价格有效期', value: 'priceValidity'},
        {title: '物资名称', value: 'prodName'},
        {title: '本次采购物资信息', value: 'endtime', to: 'ecCompetitiveOfferStageTwoMate', key: 'projectId'},

        {title: '重要商务条款', value: 'oldPayment'},
        {title: '报价要求', value: 'oldCommerce'},
        {title: '其他重要要求', value: 'oldSpecialrequests'},
        {title: '中标原则', value: 'oldWinbidPrinciple'},
      ],
    };
  },
  async created() {
    this.getOfferDetail()
  },
  computed: {
    stockaffirmId() {
      return this.$route.query.id;
    },
    appStatus() {
      return this.$route.query.appStatus;
    },
  },
  methods: {
    // 二阶段 招标委托单详情
    async getOfferDetail() {
      let query = this.$route.query
      // 
      const params = {
        bidcompetitiveId: query.id,
        statusType: query.appStatus
      }

      let data = await this.$api.ec.getBidcompetitiveView(params)
      let modifymsg = data.modifymsg.split(',')

      if (modifymsg.length == 1 && modifymsg[0] == '0') {
        // 不加东西
      } else {
        // 0、未修改 1、修改重要商务条款 2、只修改报价要求 3、只修改其他重要要求 4、只修改中标原则
        let enmu = {
          1: {title: '修改后重要商务条款', value: 'payment'},
          2: {title: '修改后报价要求', value: 'commerce'},
          3: {title: '修改后其他重要要求', value: 'specialrequests'},
          4: {title: '修改后中标原则', value: 'winbidPrinciple'}
        }
        modifymsg.map(item => {
          this.basicInfo.push(enmu[item])
        })
      }

      this.basicInfo.push({title: '商务分计算方法', value: 'gradecommerceText'})
      this.basicInfo.push({title: '商务分计算说明', value: 'packffsm'})
      this.basicInfo.push({title: '异常处理方法', value: 'exceptionHandleMethod'})
      
      if (data) {
        this.loadingfalg = false
      }
      // 数据
      this.details = data

      this.details.supplier = await this.getSupplierList(data.bidpack, data.projectId)

      // 价格有效期
      this.details['priceValidity'] = data && `${this.formatDate(data.effectiveDateBegin)} - ${this.formatDate(data.effectiveDateEnd)}`
      // 编制日期
      this.details.createdated = this.formatDate(data.createdate)

      this.details.gradecommerceText = data.gradecommerce == 1 ? '经评审的最低价法' : ''

      this.title = data.competitivePackName
      this.basicTop.data = data
      this.basicTopResult.data = data
      this.levels = Number(data.levels)
      if (this.details.fileList) {
        this.details.fileList.map((item) => {
          item.suffix = item.title.substring(item.title.lastIndexOf(".") + 1)
        });
      }
      if (this.details.projectFileList) {
        this.details.projectFileList.map((item) => {
          item.suffix = item.title.substring(item.title.lastIndexOf(".") + 1)
        })
      }
      if (this.details.enquireBillFileList) {
        this.details.enquireBillFileList.map((item) => {
          item.suffix = item.title.substring(item.title.lastIndexOf(".") + 1)
        });
      }
      data.wfnodeVOs.map((item, index) => {
        console.log('dattttttt', data, item)
        if (item.isNodeaction == 1) {
          this.levels = index + 1
          return
        }
      });
      this.hierarchy = data.wfnodeVOs.length;
    },

    formatDate(date) {
      date = new Date(date)
      
      if (String(date.getMonth() + 1).length == 1) {
        this.month = "0" + (date.getMonth() + 1);
      } else {
        this.month = date.getMonth() + 1;
      }
      if (String(date.getDate()).length == 1) {
        this.dataV = "0" + date.getDate();
      } else {
        this.dataV = date.getDate();
      }
      return `${date.getFullYear()}/${this.month}/${this.dataV}`;
    },


    // 二阶段 招标供应商列表 
    async getSupplierList(bidpackId, projectId) {
      const params = { bidpackId, projectId }

      let data = await this.$api.ec.getSupplierList(params)
      let str = ''
      data.data.forEach(item => {
        str += `${item.strcorpname}\n`
      });

      return str
    },

    toSecondDetail(to, key) {
      if (!to) {
        return
      } else {
        console.log('to', to, key, this.details[key])

        let query = {
          projectId: this.details[key]
        }

        this.$router.push({ name: to, query })
      }
    },

    ondown(item) {
      let query = {
        id: item.id,
      };
      this.$router.push({ name: "preview", query });
    },

    async auditApply(o, message) {
      let params;
      if (o == 2) {
        this.submitflag = true;
        params = {
          bidcompetitiveId: this.details.id,
          audit: 0,
          leadexam: message,
        };
      } else {
        params = {
          bidcompetitiveId: this.details.id,
          audit: 1,
          leadexam: "同意",
        };
      }

      let data = await this.$api.ec.auditBidcompetitive(params);
      if (data) {
        Toast.success("提交成功");
        setTimeout(() => {
          this.$router.push({ name: "ecCompetitiveOfferStageTwo", query: {type: 1} })
        }, 1000);
      }
    },
    onClickLeft() {
      if (this.showage) {
        // this.$router.go(-1)
        this.$router.push({ name: "ecCompetitiveOfferStageTwo", query: {type: 1} })
      } else {
        this.showage = true;
        this.title = "采购方案审批详情";
      }
    },
    onRefresh() {
      this.loading = true;
    },
    onCompression() {
      Dialog.alert({
        message: "压缩包请到电脑端查看",
      }).then(() => {
        // on close
      });
    },
    onLoad() {},
    listDetails() {},
    agree() {
      // let messageval = "";
      // if (
      //   this.details.ifonly == 1 &&
      //   this.details.stockaffirmExclusiveresultsVO &&
      //   this.details.stockaffirmExclusiveresultsVO.publicitytype == 1
      // ) {
      //   messageval = "独家结果审批全部通过后将自动发出独家结果公示，是否继续?";
      // } else if (
      //   this.details.ifonly == 1 &&
      //   this.details.stockaffirmExclusiveresultsVO &&
      //   this.details.stockaffirmExclusiveresultsVO.publicitytype == 2
      // ) {
      //   messageval = "当前独家结果为独家采购免公示，是否继续?";
      // } else {
      //   messageval = "是否同意";
      // }
      Dialog.confirm({
        title: "审批",
        message: "是否同意",
      })
        .then(() => {
          this.show = false;
          this.auditApply();
        })
        .catch(() => {
          // on cancel
        });
    },
    noagree() {
      this.title = "审批";
      this.showage = false;
    },
  },
};
</script>
<style lang="less" scoped>
.footer-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #70e6ea;
}
.footer-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #f77269;
}
/deep/.group {
  .van-cell {
    color: #8f8f8f;
  }
}
/deep/.van-cell {
  background-color: transparent;
}
/deep/.van-cell {
  font-size: 12px;
}
/deep/.van-collapse-item__content {
  font-size: 12px;
  padding: 0;
}
/deep/.van-cell__value {
  color: #333;
}
.supplies {
  margin-top: 10px;
  min-height: 160px;
  background-color: #fff;
  padding: 0.3rem;
  border-radius: 9px;
  margin: 0.3rem;
  .supplies-title {
    font-weight: 400;
    line-height: 25px;
    font-size: 13px;
  }
  .supplies-text {
    color: #8f8f8f;
    line-height: 25px;
  }
}
.record {
  margin-top: 10px;
}
</style>
